require('./bootstrap');
var $ = require("jquery");
require('jquery-countdown');
require('jquery.cookie');
require('slick-carousel');
require('jquery-scrollify');
require('tilt.js');
require('is-in-viewport');
require('./turn.min.js')

import toastr from 'toastr'
window.toastr = toastr

import Chocolat from 'chocolat'

$(document).ready(function () {
    Chocolat(document.querySelectorAll('.popup-image'))
})

toastr.options = {
    "positionClass": "toast-bottom-full-width",
    "onclick": null,
    "fadeIn": 300,
    "fadeOut": 1000,
    "timeOut": 10000,
    "extendedTimeOut": 10000,
    toastClass: 'alert',
    iconClasses: {
        error: 'alert-error',
        info: 'alert-info',
        success: 'alert-success',
        warning: 'alert-warning'
    }
}

import Panzoom from '@panzoom/panzoom';
import Echo from 'laravel-echo';
import Player from '@vimeo/player';
import Pusher from 'pusher-js';
window.Pusher = Pusher;

window.Echo = new Echo({
    broadcaster: 'pusher',
    key: process.env.MIX_PUSHER_APP_KEY,
    wsHost: process.env.MIX_PUSHER_HOST,
    wsPort: process.env.MIX_PUSHER_PORT,
    wssPort: process.env.MIX_PUSHER_PORT,
    forceTLS: false,
    encrypted: false,
    disableStats: true,
    enabledTransports: ['ws', 'wss'],
});

window.Echo.channel('dimm')
    .listen('.dimmEvent', (e) => {
        $(".bg-white-transparent").addClass("dimmed");
        $(".dimm-"+ e.celebration +"").addClass("dimm");
        $(".dimm-"+ e.celebration +"-inner").addClass("text-white");
        $(".dimm-"+ e.celebration +"-inner").removeClass("bg-white-transparent");
        $(".dimm-"+ e.celebration +"-inner").addClass("bg-dark-transparent");
        $(".iconized path").css("fill", "#ffffff");
    });

window.Echo.channel('undimm')
    .listen('.undimmEvent', (e) => {
        $(".bg-white-transparent").removeClass("dimmed");
        $(".dimm-"+ e.celebration +"").removeClass("dimm");
        $(".dimm-"+ e.celebration +"-inner").removeClass("text-white");
        $(".dimm-"+ e.celebration +"-inner").addClass("bg-white-transparent");
        $(".dimm-"+ e.celebration +"-inner").removeClass("bg-dark-transparent");
        $(".iconized path").css("fill", "#00802f");
    });

window.Echo.channel('clap')
    .listen('.clapEvent', (e) => {
        countryMap(e.country, e.celebration ,e.type);
        countClaps(e.type, e.celebration);
    });

window.Echo.channel('force-reload')
    .listen('.forceEvent', (e) => {
        console.log("Force Reload");
        location.reload();
    });

window.Echo.channel('showelements')
    .listen('.showEvent', (e) => {
        $("." + e.celebration).find(".force").removeClass("hide");
        $("." + e.celebration).find(".force-timer").addClass("hide");
    });

window.Echo.channel('hideelements')
    .listen('.hideEvent', (e) => {
        $("." + e.celebration).find(".force").addClass("hide");
        $("." + e.celebration).find(".force-timer").removeClass("hide");
    });

var count = 0;


function countClaps(type, celebration) {
    
    if (type == "clap"){
        count++;
        $(".current-counter").text(count);
    }

    if (type == "unclap"){

        if(count > 0){
            count--;
        } else {
            count = 0
        }

        $(".current-counter").text(count);
    }
}


function countryMap(country, celebration, type) {

    if (type == "clap"){

        var count = $("."+celebration+" .world-map").find("#" + country).attr("data-count");
        if (count == null) {
            count = 0;
        }

        var devided = count * 0.1;
        if(devided >= 1){
            devided = 1;
        }

        count++;
        $("."+celebration+" .world-map").find("#" + country).attr("data-count", count);
        $("."+celebration+" .world-map").find("#" + country).css("fill", "rgba(0, 128, 47," + devided + ")");
    }

    if (type == "unclap"){

        var count = $("."+celebration+ " .world-map").find("#" + country).attr("data-count");

        var devided = count * 0.1;
        if(devided >= 1){
            devided = 1;
        }

        count--;
        $("."+celebration+ " .world-map").find("#" + country).attr("data-count", count);
        $("."+celebration+ " .world-map").find("#" + country).css("fill", "rgba(0, 128, 47," + devided + ")");
    }

    if (count == 0) {
        $("."+celebration+ " .world-map").find("#" + country).css("fill", "rgba(236, 236, 236, 1)");
    }
}


$(document).ready(function () {

    if ($('.tilt').length > 0) {
        $('.tilt').tilt({
            scale: 1.01,
            maxTilt: 5,
            easing: "cubic-bezier(.03,.98,.52,.99)",
            perspective: 1000,
        });
    }

    $(".dimm-button").click(function () {
        $(this).find("span").text($(this).find("span").text() == 'Hintergrund abdunkeln' ? 'Hintergrund aufhellen' : 'Hintergrund abdunkeln');
        $(this).closest(".background-image-landing").toggleClass("dimm");
    });


    function getUserCountry() {
        if (localStorage.getItem("country") === null) {
            $.getJSON('https://api.ipdata.co/?api-key=ea7c2d157d92012192a5c2d7a01e1787b95202500851fdb616723422', function (data) {
                var countryCode = data.country_code;
                console.log(countryCode);
                localStorage.setItem("country", countryCode);
            });
        } else {
            console.log("Country Code bereits im Local Storage gesetzt: " + localStorage.getItem("country"));
        }
    }

    getUserCountry();


    function initializeMap() {
        if ($('#world-map').length > 0) {
            const elem = document.getElementById('world-map')
            const zoomInButton = document.getElementById('ranzoomen')
            const zoomOutButton = document.getElementById('rauszoomen')
            const panzoom = Panzoom(elem, {})
            zoomInButton.addEventListener('click', panzoom.zoomIn)
            zoomOutButton.addEventListener('click', panzoom.zoomOut);
        }
    };

    initializeMap();

    if ($('.timestamp').length > 0) {
        const player = new Player('phd-player')

        var timeout

        player.ready().then(function() {

            player.on('timeupdate', function (getAll)
            {       
                var time = getAll.seconds;
                var seconds = time.toString().split(".")[0]
                var hhmmss = new Date(seconds * 1000).toISOString().substr(12, 7)

                jQuery(".timestamp").each(function(){
                    var this_time = jQuery(this).data("time")
                    var next_time = jQuery(this).next(".timestamp").data("time")

                    if(hhmmss >= this_time && hhmmss < next_time){
                        jQuery(this).addClass("active")
                    } else {
                        jQuery(this).removeClass("active")
                    }
                })
            });
        });


        $(".timestamp").click(function(){
            var time = $(this).attr("data-time");
            var a = time.split(':');
            var seconds = (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]);

            player.setCurrentTime(seconds)
                .then(function(seconds) {
                    player.play();
                })
                .catch(function(error) {
                    switch (error.name) {
                        case 'RangeError':
                            break;
                
                        default:
                            break;
                    }
                });
        })
    }

    $(document).ready(function () {
        if ($('.phd-player').length > 0) {
            var urlParams = new URLSearchParams(window.location.search);
            var time = urlParams.get('time');

            const player = new Player('phd-player')

            console.log(time)

            if(time){
                var a = time.split(':');
                var seconds = (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]);

                player.setCurrentTime(seconds)
            }
        };
    })

    // TODO: Deprecated
    if ($('#pdf-canvas').length > 0) {
        viewPDF();
    }

    if ($('.pdf-container').length > 0) {
        $( ".pdf-container" ).each(function( index ) {
            jQuery(this).attr("id", "pdf-container-" + index)
            viewMultiplePDF("#pdf-container-" + index);
        });
    }

});

function viewMultiplePDF(id){
    var url = jQuery(id).find("#pdf-canvas").data("url")
    var pdfjsLib = window['pdfjs-dist/build/pdf'];

    if(process.env.MIX_ASSET_URL){
        var ASSET_URL = process.env.MIX_ASSET_URL
    } else {
        var ASSET_URL = ''
    }

    pdfjsLib.GlobalWorkerOptions.workerSrc = ASSET_URL + '/js/pdf.worker.js';

    var pdfDoc = null,
        pageNum = 1,
        pageRendering = false,
        pageNumPending = null,
        scale = 2,
        canvas = jQuery(id).find('#pdf-canvas')[0],
        ctx = canvas.getContext('2d');

    function renderPage(num) {
        pageRendering = true;

        pdfDoc.getPage(num).then(function(page) {
            var viewport = page.getViewport({scale: scale});
            canvas.height = viewport.height;
            canvas.width = viewport.width;

            var renderContext = {
                canvasContext: ctx,
                viewport: viewport
            };

            var renderTask = page.render(renderContext);

            renderTask.promise.then(function() {
                pageRendering = false;
                if (pageNumPending !== null) {
                    renderPage(pageNumPending);
                    pageNumPending = null;
                }
            });
        });

        jQuery(id).find('#page_num').html(num);

        if(num === 1){
            jQuery(id).find('#prev').prop('disabled', true);
        } else {
            jQuery(id).find('#prev').prop('disabled', false);
        }

        if(num === pdfDoc.numPages){
            jQuery(id).find('#next').prop('disabled', true);
        } else {
            jQuery(id).find('#next').prop('disabled', false);
        }
    }

    function queueRenderPage(num) {
        if (pageRendering) {
            pageNumPending = num;
        } else {
            renderPage(num);
        }
    }

    function onPrevPage() {
        if (pageNum <= 1) {
            return;
        }
        pageNum--;
        queueRenderPage(pageNum);
    }

    jQuery(id).find('#prev').click(onPrevPage);

    function onNextPage() {
        if (pageNum >= pdfDoc.numPages) {
            return;
        }
        pageNum++;
        queueRenderPage(pageNum);
    }

    jQuery(id).find('#next').click(onNextPage);

    pdfjsLib.getDocument(url).promise.then(function(pdfDoc_) {
        pdfDoc = pdfDoc_;
        jQuery(id).find('#page_count').html(pdfDoc.numPages);

        renderPage(pageNum);
    });
}

// TODO: Deprecated
function viewPDF(){
    var url = jQuery("#pdf-canvas").data("url")
    var pdfjsLib = window['pdfjs-dist/build/pdf'];

    if(process.env.MIX_ASSET_URL){
        var ASSET_URL = process.env.MIX_ASSET_URL
    } else {
        var ASSET_URL = ''
    }

    pdfjsLib.GlobalWorkerOptions.workerSrc = ASSET_URL + '/js/pdf.worker.js';

    var pdfDoc = null,
        pageNum = 1,
        pageRendering = false,
        pageNumPending = null,
        scale = 2,
        canvas = document.getElementById('pdf-canvas'),
        ctx = canvas.getContext('2d');

    function renderPage(num) {
        pageRendering = true;

        pdfDoc.getPage(num).then(function(page) {
            var viewport = page.getViewport({scale: scale});
            canvas.height = viewport.height;
            canvas.width = viewport.width;

            var renderContext = {
                canvasContext: ctx,
                viewport: viewport
            };

            var renderTask = page.render(renderContext);

            renderTask.promise.then(function() {
                pageRendering = false;
                if (pageNumPending !== null) {
                    renderPage(pageNumPending);
                    pageNumPending = null;
                }
            });
        });

        document.getElementById('page_num').textContent = num;

        if(num === 1){
            document.getElementById('prev').disabled = true;
        } else {
            document.getElementById('prev').disabled = false;
        }

        if(num === pdfDoc.numPages){
            document.getElementById('next').disabled = true;
        } else {
            document.getElementById('next').disabled = false;
        }
    }

    function queueRenderPage(num) {
        if (pageRendering) {
            pageNumPending = num;
        } else {
            renderPage(num);
        }
    }

    function onPrevPage() {
        if (pageNum <= 1) {
            return;
        }
        pageNum--;
        queueRenderPage(pageNum);
    }

    document.getElementById('prev').addEventListener('click', onPrevPage);

    function onNextPage() {
        if (pageNum >= pdfDoc.numPages) {
            return;
        }
        pageNum++;
        queueRenderPage(pageNum);
    }

    document.getElementById('next').addEventListener('click', onNextPage);

    pdfjsLib.getDocument(url).promise.then(function(pdfDoc_) {
        pdfDoc = pdfDoc_;
        document.getElementById('page_count').textContent = pdfDoc.numPages;

        renderPage(pageNum);
    });
}

// Turn JS
var flipbookEL
var flipbookPrev
var flipbookNext

$(window).resize( function() {
    if($(window).width() >= 768) {
        $(flipbookEL).turn({
            display: 'double',
            autocenter: true,
            width: flipbookEL.clientWidth, 
            height: flipbookEL.clientHeight
        })
    }
    else {
        $(flipbookEL).turn({
            display: 'single',
            autocenter: true,
            width: flipbookEL.clientWidth, 
            height: flipbookEL.clientHeight
        })
    }
})

$(document).ready( function () {
    flipbookEL = document.getElementById('flipbook');
    flipbookPrev = $('#flipbook-prev');
    flipbookNext = $('#flipbook-next');

    if (flipbookEL) {

        if($(window).width() >= 768) {
            $(flipbookEL).turn({
                display: 'double',
                autocenter: true,
                width: flipbookEL.clientWidth, 
                height: flipbookEL.clientHeight
            })
        }
        else {
            $(flipbookEL).turn({
                display: 'single',
                autocenter: true,
                width: flipbookEL.clientWidth, 
                height: flipbookEL.clientHeight
            })
        }

        $(flipbookEL).bind('turning',function (event, page, corner) {
            if(page === 20 || page === 21) {
                $('#open-video-1').show()
                $('#open-video-2').hide()
                $('#open-video-3').hide()
                $('#open-video-4').hide()
                $('#open-video-5').hide()
            }
            else if(page === 22 || page === 23) {
                $('#open-video-1').hide()
                $('#open-video-2').show()
                $('#open-video-3').hide()
                $('#open-video-4').hide()
                $('#open-video-5').hide()
            }
            else if(page === 24 || page === 25) {
                $('#open-video-1').hide()
                $('#open-video-2').hide()
                $('#open-video-3').show()
                $('#open-video-4').hide()
                $('#open-video-5').hide()
            }
            else if(page === 26 || page === 27) {
                $('#open-video-1').hide()
                $('#open-video-2').hide()
                $('#open-video-3').hide()
                $('#open-video-4').show()
                $('#open-video-5').hide()
            }
            else if(page === 28 || page === 29) {
                $('#open-video-1').hide()
                $('#open-video-2').hide()
                $('#open-video-3').hide()
                $('#open-video-4').hide()
                $('#open-video-5').show()
            }
            else {
                $('#open-video-1').hide()
                $('#open-video-2').hide()
                $('#open-video-3').hide()
                $('#open-video-4').hide()
                $('#open-video-5').hide()
            }
        });
    }

    flipbookPrev.on('click', function(e) {
        $(flipbookEL).turn('previous');
    })

    flipbookNext.on('click', function(e) {
        $(flipbookEL).turn('next');
    })
})